<template>
  <base-header>
    <template #top-header>
      <header-date-control/>
    </template>
    {{ title }}
    <template #title-icon v-if="showIcons">
      <icon-button
          :key="`${$route.fullPath}-FAVORITE`"
          class="bookmark"
          @click.capture="toggleFave"
          icon="bookmark"
          :icon-style="isFavorite ? 'solid' : undefined"
          :aria-label="bookmarkLabel">
      </icon-button>
    </template>
  </base-header>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {dateToIsoDateString} from "@/utils/helper";
import HeaderDateControl from "@/components/HeaderDateControl.vue";
import BaseHeader from "@/components/header/BaseHeader.vue";
import IconButton from "@/components/IconButton.vue";

const ROUTES = ["Info", "Favoriten"]

@Component({
  components: {IconButton, BaseHeader, HeaderDateControl},
})
export default class VerseHeader extends Vue {

  fave = false

  async mounted() {
    this.isFavorite = (await this.storage.getAllFavorites())
        .includes(this.getDateFromCurrentRoute())
    window.addEventListener("keydown", this.initKeydownListeners)
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.initKeydownListeners)
  }

  async initKeydownListeners(event: KeyboardEvent) {
    if (event.repeat) return
    if (this.$route.fullPath === "/about") return
    switch (event.key.toLowerCase()) {
      case "n":
        await this.toggleFave()
        return
    }
  }

  get isHome(): boolean {
    return this.$router.currentRoute.path === "/"
  }

  getDateFromCurrentRoute(): string {
    if (this.isHome) {
      return dateToIsoDateString()
    }
    return this.$router.currentRoute.params.date
  }

  async toggleFave() {
    const date = this.getDateFromCurrentRoute()
    this.isFavorite = !this.isFavorite
    if (this.isFavorite) {
      await this.$store.dispatch("addFavorite", date)
    } else {
      await this.$store.dispatch("removeFavorite", date)
    }
  }

  set isFavorite(fave: boolean) {
    this.fave = fave
  }

  get isFavorite(): boolean {
    return this.fave
  }

  get title(): string {
    if (this.$route.meta?.showTitle) {
      return this.$route.meta.title
    }
    return this.$store.getters.currentVerseTitle
  }

  get showIcons(): boolean {
    return !ROUTES.includes(this.$route.name as string)
  }

  get bookmarkLabel(): string {
    return (this.isFavorite ? "Remove {} from bookmarks" : "Add {} to bookmarks")
        .replace("{}", this.$store.getters.currentVerseTitle)
  }

  @Watch("$route.fullPath")
  async onRoutePathChange() {
    this.isFavorite = (await this.storage.getAllFavorites())
        .includes(this.$router.currentRoute.params.date)
  }

}
</script>
