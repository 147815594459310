<template>
  <div class="refresh-flag-container" :active="show">
    <div class="flag-content">
      <div class="message-container">
        <icon-button
            class="brightness dev-toggle"
            icon="multiply"
            width="20"
            @click.stop="hideFlag"
            :aria-label="`Toggle color mode to ${$store.getters.isDarkMode ? 'light' : 'dark'} mode`"
        />

        <p>App update available.</p>
      </div>

      <button class="refresh-container" @click="$listeners.click">
        <p>
          Refresh
        </p>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class RefreshFlag extends Vue {
  @Prop({default: false}) active!: boolean;

  hide = false

  hideFlag() {
    this.hide = true
  }

  get show(): boolean {
    if (this.hide) return false
    return this.active
  }
}
</script>

<style scoped lang="less">
@import '@/style/main.less';

.message-container, .flag-content, .refresh-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.refresh-flag-container {
  position: fixed;
  left: auto;
  right: auto;
  top: -5rem;
  opacity: 0;

  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;

  .transition-cubic2(600ms);
  transition-property: top, opacity;

  &[active="true"] {
    top: 0%;
    opacity: 1;
  }

  p {
    font-weight: bold;
  }

  .flag-content {
    position: relative;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;

    padding-left: .5rem;
    padding-right: .5rem;

    justify-content: space-between;

    .neo-1-shadow;

    color: var(--flag-color);

    backdrop-filter: invert(40%) blur(2px) contrast(240%);
    background-color: var(--flag-bg-color);
  }

  ::v-deep svg {
    fill: var(--flag-color);
  }

  .refresh-container {
    .transparent-btn();
    .rounded;
    margin: .75rem;
    padding: .75rem;

    &:hover {
      backdrop-filter: brightness(80%);
    }

    p {
      margin: 0;
      color: var(--flag-color-accent);
    }
  }
}
</style>
