<template>
  <content-view class="home">
    <verse-list-display/>
    <template #footer>
      <bottom-nav-verses/>
    </template>
  </content-view>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import VerseDisplay from "@/components/VerseDisplay.vue";
import CopyIcon from "@/components/CopyIcon.vue";
import ContentView from "@/components/views/ContentView.vue";
import FixedCopyIcon from "@/components/FixedCopyIcon.vue";
import BottomNavVerses from "@/components/BottomNavVerses.vue";
import VerseListDisplay from "@/components/views/VerseListDisplay.vue";

@Component({
  components: {
    ContentView,
    VerseListDisplay,
    BottomNavVerses,
    FixedCopyIcon,
    CopyIcon,
    VerseDisplay,
  },
})
export default class HomeView extends Vue {

}
</script>

<style lang="less" scoped>
::v-deep footer {
  position: fixed;
  left: auto;
  bottom: 1.5rem;
  right: auto;
  z-index: 500;

  display: flex;
  align-items: center;

  //background: red;
  width: 100%;
  padding: 0;
  height: 3.5rem;
  max-width: var(--max-width);
}
</style>
