<template>
  <section :data-lang="lang" class="verse-display">
    <p v-for="line in verseAsArray" class="verse-text">
      <template>
        <span>{{ getLine(line) }}</span>
      </template>
    </p>
    <h5>
      <span>{{ book }}</span>
      <icon-button
          class="copy-verse"
          icon="copy-alt"
          width="16"
          @click.stop="copyVerse"
      />
    </h5>
  </section>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {copyCurrentVerse, stripPunctuation} from "@/utils/helper";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class VerseDisplay extends Vue {
  @Prop({default: "de"}) private lang!: "de" | "de2000" | "he" | "en" | "gr";
  @Prop() private book!: string;
  @Prop() private verseText!: string | string[];

  get isGermanEBU(): boolean {
    return this.lang === "de" && typeof this.verseText === "string";
  }

  get isHebrew(): boolean {
    return !!this.lang && this.lang === "he"
  }

  get verseAsArray(): string[] {
    return this.isGermanEBU ? [this.verseText as string] : this.verseText as string[];
  }

  getLine(line: string): string {
    if (this.isHebrew && this.$store.state.simplified) return this.devocalized(line)
    return line
  }

  devocalized(words: string) {
    return stripPunctuation(words)
  }

  async copyVerse() {
    await copyCurrentVerse(`${this.$store.getters.currentVerseTitle}
${this.verseAsArray.map(this.getLine).join("\n")}

https://talosung.de/date/${this.$store.state.selectedDate}`)
  }
}
</script>

<style scoped lang="less">
@import '@/style/main.less';

.verse-display {
  display: flex;
  flex-direction: column;
  background: var(--background-card);
  text-align: left;
  .rounded;
  .neo-1();

  &[data-lang="he"], &[data-lang="hebrew"] {
    text-wrap: normal;

    * {
      text-align: right;
      direction: rtl;
    }
  }

  p {
    font-size: large;
    font-weight: 500;
    margin-bottom: .75rem;
  }

  h5 {
    margin-top: .5rem;

    ::v-deep .icon {
      display: inline-flex;
      margin: 0;
      width: 30px;
      height: 30px;
      top: .5rem;
    }
  }
}

button {
  .transparent-btn();
  .text;
}
</style>
