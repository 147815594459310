<template>
  <div class="bottom-nav-container">
    <nav class="main-nav">
      <icon-button
          class="brightness dev-toggle animated-toggle"
          :started-animation="animated"
          @click.capture.stop="toggleColorMode"
          :icon="colorModeIcon"
          :aria-label="`Toggle color mode to ${$store.getters.isDarkMode ? 'light' : 'dark'} mode`"
          padded="true"
      />
      <icon-button
          v-if="isAboutPage"
          link="true"
          class="home"
          icon="estate"
          to="/"
          aria-label="Go to home page"
          padded="true"
      />
      <icon-button
          v-if="!isAboutPage"
          class="copy"
          :class="{loading:!$store.getters.isReady}"
          @click.stop="copyVerses"
          icon="copy-alt"
          :active="copied"
          :aria-label="`Copy ${this.$store.getters.currentVerseTitle}`"
          padded="true"
      />
      <icon-button
          link="true"
          class="bookmark"
          icon="bookmark"
          :icon-style="isFavoritesView ? 'solid' : undefined"
          :active="isFavoritesView"
          :to="to"
          aria-label="Go to bookmarks page"
          padded="true"
      />
      <router-link :to="$route.path === '/about' ? '/' : '/about'" class="about-link" aria-label="Go to about page">
        <unicon name="info" :width="25"></unicon>
      </router-link>
    </nav>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {copyCurrentVerse, hasHighlightedText} from "@/utils/helper";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class BottomNav extends Vue {

  clickTimer: NodeJS.Timeout | null = null
  animated = false

  copied = false;

  mounted() {
    window.addEventListener("keydown", this.initKeydownListeners)
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.initKeydownListeners)
  }

  copyVerses() {
    copyCurrentVerse(this.$store.getters.versesString)
        .then(this.setCopied);
  }

  setCopied() {
    this.copied = true
    setTimeout(() => {
      this.copied = false
    }, 3000)
  }

  get to(): string {
    return this.isFavoritesView ? "/" : "/favorites"
  }

  get isFavoritesView(): boolean {
    return this.$route.fullPath === "/favorites"
  }

  get isAboutPage(): boolean {
    return this.$route.fullPath === "/about"
  }

  async initKeydownListeners(event: KeyboardEvent) {
    if (event.repeat) return
    if (this.$route.fullPath === "/about") return
    switch (event.key.toLowerCase()) {
      case "c":
        if (hasHighlightedText()) return
        await copyCurrentVerse(this.$store.getters.versesString)
            .then(this.setCopied)
        return
    }
  }

  clearTimer() {
    if (this.clickTimer) {
      clearTimeout(this.clickTimer)
    }
  }

  animatedTurn() {
    this.animated = true
    setTimeout(() => {
      this.animated = false
    }, 1000)
  }

  toggleColorMode() {
    this.clearTimer()
    this.clickTimer = setTimeout(async () => {
      this.animatedTurn();
      await this.themeService.toggleMode();
      this.clearTimer()
    }, 200)
  }

  get colorModeIcon() {
    return this.$store.getters.isDarkMode ? "bright" : "brightness-half"
  }

}
</script>

<style scoped lang="less">
@import '@/style/main.less';

.bottom-nav-container {
  .bottom-nav-container();

  &:after {
    content: "";
    display: block;
    position: fixed;
    z-index: 490;
    pointer-events: none;

    background: linear-gradient(180deg, var(--gradient-start-color) 5%, var(--gradient-end-color) 100%);
    height: 5rem;
    width: 100%;

    left: 0;
    right: 0;
    bottom: 0;
  }
}

.bookmark {
  &:after {
    background: var(--bookmark-link-bg-color);
  }
}

.copy {
  .transition-default;
  transition-property: transform;
  transform: rotate(0deg);

  &:after {
    background: var(--favorite-link-bg-color);
  }

  &.loading {
    background: none;
    pointer-events: none;
    opacity: 0.2 !important;
    transform: rotate(30deg);
  }
}
</style>
