<template>
  <div class="copy-icon-container">
    <div class="copied-overlay" :data-copied="this.copied">
      <unicon @click.capture="copyVerses" name="copy-alt" class="copy-icon" width="25"></unicon>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component
export default class FixedCopyIcon extends Vue {

  copied = false;

  copyVerses() {
    if (this.$store.getters.isReady) {
      navigator.clipboard.writeText(this.$store.getters.versesString)
          .then(() => {
            this.copied = true
            setTimeout(() => {
              this.copied = false
            }, 3000)
          });
    }
  }
}
</script>

<style lang="less">
[data-copied="true"] {
  background: var(--favorite-link-bg-color) !important;
}
</style>
<style scoped lang="less">
@import '@/style/main.less';

.copy-icon-container {
  @transition: cubic-bezier(0.16, 1, 0.3, 1);
  @duration: 700ms;

  .centered-fixed;

  .copied-overlay {
    .rounded-icon;

    position: relative;
    z-index: 430;

    background: transparent;
    transition-duration: @duration;
    transition-timing-function: @transition;

    left: 0;
    right: auto;

    &[data-copied="true"] {
      background: var(--favorite-link-bg-color) !important;

      ::v-deep svg {
        fill: #0c0c0c !important;
      }

      &:after {
        opacity: 1;
        right: -100%;
      }
    }

    &:after {
      content: "Kopiert";
      display: block;
      opacity: 0;
      position: absolute;
      .text;
      z-index: 300;

      right: -50%;
      bottom: 20%;

      pointer-events: none;

      transition-duration: calc(@duration - 200ms);
      transition-timing-function: @transition;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.copy-icon {
  cursor: pointer;

  &.success {
    position: absolute;
    left: auto;
    right: auto;
  }
}
</style>
