import { render, staticRenderFns } from "./VerseHeader.vue?vue&type=template&id=7e2ab2a1&scoped=true"
import script from "./VerseHeader.vue?vue&type=script&lang=ts"
export * from "./VerseHeader.vue?vue&type=script&lang=ts"
import style0 from "./VerseHeader.vue?vue&type=style&index=0&id=7e2ab2a1&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e2ab2a1",
  null
  
)

export default component.exports