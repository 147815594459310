import { render, staticRenderFns } from "./BaseHeader.vue?vue&type=template&id=76a7a9f1&scoped=true"
var script = {}
import style0 from "./BaseHeader.vue?vue&type=style&index=0&id=76a7a9f1&prod&lang=less"
import style1 from "./BaseHeader.vue?vue&type=style&index=1&id=76a7a9f1&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76a7a9f1",
  null
  
)

export default component.exports