<template>
  <router-link v-if="link"
               :to="to"
               tag="button"
               class="icon"
               :class="`icon.${icon}`"
               :active="active"
               :padded="isPadded">
    <slot></slot>
    <unicon v-if="!custom" :name="icon" :width="width" :icon-style="iconStyle"></unicon>
  </router-link>
  <button v-else
          class="icon"
          v-on="$listeners"
          :class="`icon.${icon}`"
          :active="active"
          :padded="isPadded">
    <slot></slot>
    <unicon v-if="!custom" :name="icon" :width="width" :icon-style="iconStyle"></unicon>
  </button>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class IconButton extends Vue {
  @Prop({default: "copy-alt"}) icon!: string
  @Prop({default: 25}) width!: number
  @Prop({default: undefined}) iconStyle!: "solid" | "thin" | "monochrome" | undefined
  @Prop({default: false}) link!: boolean
  @Prop({default: ""}) to!: string
  @Prop({default: false}) custom!: boolean

  @Prop({default: false}) active!: boolean
  @Prop({default: false}) padded!: boolean

  get isPadded(): boolean {
    return this.padded
  }
}
</script>

<style scoped lang="less">
@import '@/style/main.less';

.icon {
  position: relative;
  width: 1.75rem;
  height: 1.75rem;

  padding: .5rem .5rem;
  margin: 1rem;
  cursor: pointer;
  .centered;
  .underlined-anim();
  .transparent-btn();

  &:after {
    bottom: -.2rem;
  }

  &[padded] {
    padding: 1.25rem;
  }
}

.unicon, .icon, .icon div {
  height: 25px;
}

.unicon, .icon, .icon div {
  height: 25px;
}
</style>
