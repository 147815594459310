import {Losung} from "@/typings/types";
import IService from "@/services/IService";
import {dateToIsoDateString} from "@/utils/helper";
import {createClient, SupabaseClient} from "@supabase/supabase-js";
import CacheService from "@/services/CacheService";
import DayJS from "@/utils/dayJs";

export default class LosungService implements IService {

    private supabaseClient!: SupabaseClient;
    static readonly cacheService = new CacheService();

    constructor() {
    }

    public async init() {
        if (this.ready) return Promise.resolve();
        LosungService.cacheService.init()
        const supabaseUrl = process.env.VUE_APP_SUPABASE_URL as string
        const supabaseKey = process.env.VUE_APP_SUPABASE_KEY as string
        this.supabaseClient = createClient(supabaseUrl, supabaseKey)
    }

    public async loadInitial(){
        const currentLosung = await this.getForCurrentDate()
        LosungService.cacheService.cacheData("CURRENT_LOSUNG", currentLosung)
        return await this.getSurroundingLosungen()
    }

    public destroy(): void {
        LosungService.cacheService.removeCacheData("CURRENT_LOSUNG")
    }

    public async getForCurrentDate() {
        const current_losung = await this.getForSingleLosungForDate(new Date())
        return current_losung
    }

    public async getForSingleLosungForDate(date: Date): Promise<Losung> {
        const {data: current_losung, error} = await this.supabaseClient
            .from('talosung_year')
            .select('*')
            .eq("date", dateToIsoDateString(date))
            .limit(1)
            .single()
        return current_losung
    }

    public async getByDateString(dateString: string) {
        const {data: talosung_year, error} = await this.supabaseClient
            .from('talosung_year')
            .select('*')
            .eq("date", dateString)
        return talosung_year
    }

    public async getSurroundingLosungen(surroundingDays: number = 30): Promise<Losung[] | null> {
        return await this.getSurroundingLosungenFromDate(new Date(), surroundingDays)
    }

    public async getSurroundingLosungenFromDate(startingDate: Date | string, surroundingDays: number = 30): Promise<Losung[] | null> {
        let _date: Date
        if (!(startingDate instanceof Date)) {
            _date = DayJS(startingDate).toDate();
        } else {
            _date = startingDate
        }

        const _daysInFuture = Math.abs(surroundingDays || 30)
        const start = -(_daysInFuture / 2)
        const {data: talosung_year, error} = await this.supabaseClient
            .from('talosung_year')
            .select('*')
            .in("date", [...Array(_daysInFuture)]
                .map((_, i) => {
                    const newDay = DayJS(_date).add((start + i), "day");
                    return dateToIsoDateString(newDay.toDate());
                }))
            .limit(_daysInFuture)
        const formattedLosungen = talosung_year?.reduce((prev, val) => ({
            ...prev,
            [val.date]: val,
        }), {})
        LosungService.cacheService.cacheData("FUTURE_LOSUNGEN", formattedLosungen, true)
        return formattedLosungen
    }

    get ready() {
        return !!this.supabaseClient
    }
}
