import Vue from "vue";
import CacheService from "@/services/CacheService";

export default Vue.extend({
    data() {
        return {
            refreshing: false,
            registration: null as ServiceWorkerRegistration | null,
            updateExists: false,
        }
    },
    created() {
        document.addEventListener("swUpdate", this.updateAvailable, {once: true})

        navigator.serviceWorker.addEventListener("controllerchange", () => {
            if (this.refreshing) return
            const cacheService = CacheService.instance
            cacheService.clearLegacyCache()
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload()
        })
    },
    methods: {
        updateAvailable(event: any) {
            // Store the SW registration so we can send it a message
            // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
            // To alert the user there is an update they need to refresh for

            this.registration = event.detail
            this.updateExists = true
            CacheService.instance?.clearLegacyCache()
            console.log("[PWA]", "Clear cache on update.")
            window.location.reload()
        },
        refreshPwa() {
            const cacheService = CacheService.instance
            cacheService.clearLegacyCache()
            console.log("[PWA]", "Clear cache on update.")
            this.updateExists = false

            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({type: "SKIP_WAITING"})
        },
    },
});
