<template>
  <transition name="fade">
    <section v-if="$store.getters.isReady" :data-lang="lang" class="verse-display">
      <p v-for="line in verseAsArray" class="verse-text">
        <template>
          <span>{{ getLine(line) }}</span>
        </template>
      </p>
      <h5>
        <span>{{ losungDataByLang.book }}</span>
        <icon-button
            class="copy-verse"
            icon="copy-alt"
            width="16"
            @click.stop="copyVerse"
        />
      </h5>
    </section>
    <section v-else class="verse-display skeleton loading">
      <p class="verse-text"><span></span></p>
      <h5><span></span></h5>
    </section>
  </transition>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {copyCurrentVerse, stripPunctuation} from "@/utils/helper";
import IconButton from "@/components/IconButton.vue";
import {Losung} from "@/typings/types";

@Component({
  components: {IconButton},
})
export default class VerseDisplay extends Vue {
  @Prop({default: "de"}) private lang!: "de" | "de2000" | "he" | "en" | "gr";

  get isHebrew(): boolean {
    return this.lang === "he"
  }

  get verseAsArray(): string[] {
    return typeof this.losungDataByLang.text === "string" ? [this.losungDataByLang.text as string] : this.losungDataByLang.text as string[];
  }

  get losungDataByLang() {
    if (this.$store.getters.isReady) {
      const currentLosung: Losung = this.$store.getters.currentLosung
      switch (this.lang) {
        case "de":
          return {
            book: currentLosung.book.book_de,
            text: currentLosung.text.german,
          }
        case "de2000":
          return {
            book: currentLosung.book.book_de,
            text: currentLosung.text.german_sch2000,
          }
        case "he":
          return {
            book: currentLosung.book.book_he,
            text: currentLosung.text.hebrew,
          }
        case "gr":
          return {
            book: currentLosung.book.book_gr!,
            text: currentLosung.text.greek,
          }
      }
    }
    return {
      book: "",
      text: "",
    };
  }

  getLine(line: string): string {
    if (this.isHebrew && this.$store.state.simplified) return this.devocalized(line)
    return line
  }

  devocalized(words: string) {
    return stripPunctuation(words)
  }

  async copyVerse() {
    await copyCurrentVerse(`${this.$store.getters.currentVerseTitle}
${this.verseAsArray.map(this.getLine).join("\n")}

https://talosung.de/date/${this.$store.state.selectedDate}`)
  }
}
</script>

<style>
:root {
  --loading-grey: #ededed;
}
</style>

<style scoped lang="less">
@import '@/style/main.less';

.verse-display {
  display: flex;
  flex-direction: column;
  background: var(--background-card);
  text-align: left;
  .rounded;
  .neo-1();

  &[data-lang="he"], &[data-lang="hebrew"] {
    text-wrap: balance;

    * {
      text-align: right;
      direction: rtl;
    }
  }

  p {
    font-size: large;
    font-weight: 500;
    margin-top: .75rem;
    margin-bottom: .1rem;
  }

  h5 {
    margin-top: .5rem;
    margin-bottom: .75rem;

    ::v-deep .icon {
      display: inline-flex;
      margin: 0;
      width: 30px;
      height: 30px;
      top: .5rem;
    }
  }
}

button {
  .transparent-btn();
  .text;
}

.skeleton {
  .verse-text, h5 {
    span {
      width: 50px;
      height: 18px;
      display: block;
      //background-color: white;
    }
  }

  h5 {
    height: 10px;
    max-width: 180px;
  }
}

.loading h5,
.loading .verse-text {
  .loading-skeleton;
}

.loading h5 {
  min-height: 1.6rem;
  border-radius: 4px;
  animation-delay: .1s;
}

.loading .verse-text {
  min-height: 4rem;
  border-radius: 4px;
  animation-delay: .06s;
}
</style>
