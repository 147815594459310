<template>
  <content-view class="favorites">
    <div id="favorites-list">
      <section
          v-for="date in favorites"
          class="favorite-item neo-inner-1"
          :unstarred="isUnstarred(date)">
        <div class="title">
          <unicon
              name="bookmark"
              class="i-bookmark"
              :icon-style="isUnstarred(date) ? undefined: 'solid'"
              :data-unstarred="isUnstarred(date)"
              width="20"
              @click.capture="toggle(date)"></unicon>

        <router-link :to="`/date/${date}`" tag="p" class="excerpt">
          <div class="date-verse-container">
            <p>{{ excerpt(date) }}</p>
            <small>{{ localeDate(date) }}</small>
          </div>
        </router-link>
        </div>

        <router-link :to="`/date/${date}`">
          <unicon name="arrow-right" class="arrow-icon" width="30"></unicon>
        </router-link>
      </section>
    </div>

  </content-view>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import ContentView from "@/components/views/ContentView.vue";
import {Losung} from "@/typings/types";
import DayJS from "@/utils/dayJs";

@Component({
  components: {ContentView},
})
export default class FavoritesView extends Vue {

  unstarred: string[] = []

  get favorites() {
    return this.storage.favorites.reverse().filter((r: any) => !!r)
  }

  isUnstarred(date: string): boolean {
    return this.unstarred.includes(date)
  }

  toggle(date: string) {
    if (this.isUnstarred(date)) {
      this.unstarred = this.unstarred.filter(item => date !== item)
      this.storage.addDateAsFavorite(date)
      return
    }
    this.unstarred.push(date)
    this.storage.removeDateFromFavorites(date)
  }

  verseForDate(date: string): Losung {
    return this.$store.getters.losungForDate(date)
  }

  excerpt(date: string) {
    const losung = this.verseForDate(date);
    return `${losung.book.book_de} ${losung.book.chapter}, ${losung.book.verses}`
  }

  localeDate(date: string) {
    return DayJS(date).toDate().toLocaleDateString()
  }
}
</script>

<style scoped lang="less">
@import '@/style/main.less';
@margin: 1.25rem;

.favorite-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .transition-quicker;

  text-align: left;

  margin-top: @margin;
  margin-bottom: @margin;
  padding-top: 0;
  padding-bottom: 0;

  &[unstarred] {
    box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent;
  }
}

.unicon, ::v-deep svg {
  width: 30px;
  height: 30px;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .unicon, ::v-deep svg {
    width: 20px;
    height: 20px;
    margin-right: 1.5rem;
    cursor: pointer;

    &[data-unstarred="true"] {
      fill: var(--text-color);
    }
  }
}

a {
  height: 30px;
}

.title {
  display: flex;
  flex-grow: 1;
}

.arrow-icon {
  margin-left: 1.5rem;
}

.excerpt {
  display: flex;
  align-self: center;
  cursor: pointer;
}

.date-verse-container {
  display: flex;
  flex-direction: column;

  p{
    margin: 0;
    margin-bottom: .2rem;
  }

  small {
    font-size: .7rem;
  }
}
</style>
