<template>
  <div class="verse-list">
    <verse-display
        key="verse-de"
        lang="de"
        loading="true"
        :book="this.currentLosung.book.book_de"
        :verse-text="this.currentLosung.text.german"/>
    <verse-display
        key="verse-he"
        lang="he"
        :book="this.currentLosung.book.book_he"
        :verse-text="this.currentLosung.text.hebrew"/>
    <verse-display
        v-if="isGreek"
        key="verse-gr"
        lang="gr"
        :book="`${this.currentLosung.book.book_gr}`"
        :verse-text="this.currentLosung.text.greek"/>
    <verse-display
        key="verse-de2000"
        lang="de2000"
        :book="`${this.currentLosung.book.book_de} (SCH2000)`"
        :verse-text="this.currentLosung.text.german_sch2000"/>
    <!--    <verse-display-->
    <!--        key="verse-en"-->
    <!--        lang="en"-->
    <!--        :book="this.currentLosung.book.book_en"-->
    <!--        :verse-text="this.currentLosung.text.english"/>-->
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {Losung} from "@/typings/types";
import VerseDisplay from "@/components/VerseDisplay.vue";
import CopyIcon from "@/components/CopyIcon.vue";
import ContentView from "@/components/views/ContentView.vue";
import FixedCopyIcon from "@/components/FixedCopyIcon.vue";
import BottomNavVerses from "@/components/BottomNavVerses.vue";

@Component({
  components: {
    BottomNavVerses,
    FixedCopyIcon,
    BaseView: ContentView,
    CopyIcon,
    VerseDisplay,
  },
})
export default class VerseListDisplay extends Vue {

  get currentLosung(): Losung {
    if (this.$route.name === "by-date") {
      return this.$store.getters.losungForSelectedDate
    }
    return this.$store.getters.currentLosung
  }

  get isGreek(): boolean {
    return !!this.currentLosung?.book?.book_gr
  }

}
</script>

<style lang="less" scoped>
::v-deep footer {
  position: fixed;
  left: auto;
  bottom: 1.5rem;
  right: auto;
  z-index: 500;

  display: flex;
  align-items: center;

  //background: red;
  width: 100%;
  padding: 0;
  height: 3.5rem;
  max-width: var(--max-width);
}
</style>
