<template>
  <div class="header-date-control">
    <icon-button
        :hide="!showIcons"
        :enabled="hasPrevious || isCurrent"
        link="true"
        class="arrow arrow-left"
        icon="arrow-left"
        :to="toPrevious"
        :aria-label="`Yesterday (${getDateString(previousDate)})`">
    </icon-button>

    <router-link to="/" :aria-label="`Today (${dateString})`">
      <h4
          class="date"
          :data-non-current="!isCurrent">{{ this.dateString }}</h4>
    </router-link>

    <icon-button
        :hide="!showIcons"
        :enabled="hasNext"
        link="true"
        class="arrow arrow-right"
        icon="arrow-right"
        :to="toNext"
        :aria-label="`Tomorrow (${getDateString(nextDate)})`">
    </icon-button>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import dayjs from "dayjs";
import {dateToIsoDateString} from "@/utils/helper";
import IconButton from "@/components/IconButton.vue";
import DayJS from "@/utils/dayJs";

const EARLIEST_DATE = "2022-01-01"

@Component({
  methods: {DayJS, dayjs},
  components: {IconButton},
})
export default class HeaderDateControl extends Vue {

  refreshKey = -1

  mounted() {
    window.addEventListener("keydown", this.initKeydownListeners)
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.initKeydownListeners)
  }

  async initKeydownListeners(event: KeyboardEvent) {
    if (event.repeat) return
    if (this.$route.fullPath === "/about") return
    switch (event.key.toLowerCase()) {
      case "b":
      case "f":
      case "s":
        await this.$router.push("/favorites")
        return
      case "a":
        await this.$router.push("/about")
        return
      case "t":
        await this.$router.push("/")
        return
      case "arrowright":
        if (this.hasNext) await this.$router.push(this.toNext)
        return
      case "arrowleft":
        if (this.hasPrevious) await this.$router.push(this.toPrevious)
        return
    }
  }

  get previousDate() {
    return this.addDays(this.getDateFromCurrentRoute(), -1)
  }

  get hasPrevious() {
    return !DayJS(this.dateString).isBefore(DayJS(EARLIEST_DATE));
  }

  get toPrevious() {
    if (!this.hasPrevious) {
      return `/date/${EARLIEST_DATE}`
    }
    return `/date/${this.previousDate}`
  }

  get nextDate() {
    return this.addDays(this.getDateFromCurrentRoute(), 1)
  }

  get hasNext() {
    if (!!this.$route.meta?.skipEnabled) {
      return true
    }
    return !["/about", "/favorites"].includes(this.$route.fullPath)
        && !this.isCurrent
        && !this.$route.path.endsWith(dateToIsoDateString())
        && !DayJS(this.dateString).isAfter(DayJS());
  }

  get toNext() {
    if (this.$route.meta?.skipEnabled) return `/date/${this.nextDate}`
    if (this.isCurrent) return "/"
    if (!this.hasNext) {
      return this.$route.fullPath
    }
    return `/date/${this.nextDate}`
  }

  get isCurrent(): boolean {
    return this.isHome || this.getDateFromCurrentRoute() === dateToIsoDateString()
  }

  get dateString() {
    return DayJS(this.$store.state.selectedDate).toDate().toLocaleDateString()
  }

  get isHome(): boolean {
    this.refreshKey;
    return this.$router.currentRoute.path === "/"
  }

  getDateString(dateStr: string): string {
    return DayJS(dateStr).toDate().toLocaleDateString()
  }

  getDateFromCurrentRoute(): string {
    if (this.isHome) {
      return dateToIsoDateString()
    }
    return this.$router.currentRoute.params.date
  }

  addDays(current: string, days: number) {
    const date = DayJS(current)
    const UNIT = "day"
    return dateToIsoDateString(
        days > 0
            ? date.add(days, UNIT)
            : date.subtract(Math.abs(days), UNIT),
    )
  }

  get showIcons(): boolean {
    // return this.$route.fullPath !== "/favorites"
    return true
  }

  @Watch("$route.fullPath")
  onRoutePathChange() {
    this.refreshKey = this.refreshKey + 1;
  }

}
</script>

<style scoped lang="less">
@import '@/style/main.less';

.date {
  margin: 0 var(--mg-small);
  cursor: pointer;
  padding: .5rem .75rem;
  box-sizing: border-box !important;
  .rounded;
  border: 1px solid transparent;

  min-width: 120px;

  position: relative;
  .centered;

  .underlined-anim();

  &:after {
    background: var(--text-color);
    width: 60%;
  }

  &[data-non-current="true"] {

    &:after {
      background: var(--text-color-off);
      width: 10%;
    }
  }
}

::v-deep .unicon {
  width: 30px;
  height: 30px;
  cursor: pointer;

  &.disabled {
    opacity: .4;
    cursor: default;
  }
}

.arrow {
  .centered;
  .transition-quicker;
  box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent;
  border-radius: 100%;
  padding: 1.25rem;

  opacity: .2;

  &[enabled] {
    opacity: 1;

    &:hover {
      background: var(--background-card-shadow-top);
      .neo-inner-1-smaller;
    }
  }
}

[hide="true"] {
  opacity: 0;
}
</style>
