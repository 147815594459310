<template>
  <component :is="component" :title="$attrs.title"/>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import VerseHeader from "@/components/header/VerseHeader.vue";

@Component
export default class Header extends Vue {

  get component(): any {
    return VerseHeader
  }

}
</script>

<style lang="less">
.header-date-control {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--mg-xsmall);
  padding-right: var(--mg-xsmall);

  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
