import { render, staticRenderFns } from "./VerseListDisplay.vue?vue&type=template&id=b07f7a1e&scoped=true"
import script from "./VerseListDisplay.vue?vue&type=script&lang=ts"
export * from "./VerseListDisplay.vue?vue&type=script&lang=ts"
import style0 from "./VerseListDisplay.vue?vue&type=style&index=0&id=b07f7a1e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b07f7a1e",
  null
  
)

export default component.exports