<template>
  <div class="date-picker-trigger">
    <div class="search-trigger">
      <icon-button
          icon="calendar-alt"
          width="25"
          @click.stop="openOverlay"/>
    </div>

    <transition name="fade1">
      <div v-show="showOverlay" class="picker-overlay">
        <div class="overlay-content">
          <h2>Datum wählen:</h2>
          <picker v-model="date" :open="showOverlay" valueType="format" @input="onDatePick"></picker>
        </div>

        <div class="backdrop" @click.capture="showOverlay = false"></div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import Vue2DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {dateToIsoDateString} from "@/utils/helper";
import IconButton from "@/components/IconButton.vue";
import DayJs from "@/utils/dayJs";

@Component({
  components: {
    IconButton,
    Picker: Vue2DatePicker
  }
})
export default class DatePicker extends Vue {
  date = dateToIsoDateString()
  showOverlay = false

  openOverlay() {
    this.showOverlay = true
  }

  onDatePick() {
    const date = DayJs(this.date);
    if (date.isBefore("2022-01-01")) {
      this.$router.replace({
        path: "/date/2022-01-01"
      })
    } else if (date.isAfter(dateToIsoDateString())) {
      if (this.$route.params.date === dateToIsoDateString()) {
        return
      }
      this.$router.replace({
        path: `/date/${dateToIsoDateString()}`
      })
    }
    this.$router.replace({
      path: `/date/${this.date}`
    })
  }
}
</script>

<style scoped lang="less">
@import '@/style/main.less';

.date-picker-trigger {
  position: relative;
  z-index: 400;

  .overlay-content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 200;
    padding-bottom: 300px;
  }

  .picker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;

    display: flex;
    align-items: center;
    justify-content: center;

    .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -20;
      backdrop-filter: blur(8px);
      opacity: 1;
    }
  }
}

.search-trigger {
  margin-right: 2rem;
}
</style>

<style lang="less">
@import '@/style/main.less';

.mx-datepicker {
  .mx-input-wrapper {
    input {
      background: var(--bg-color) !important;
      color: var(--text-color) !important;
    }

    .mx-icon-calendar {
      svg {
        fill: var(--text-color);
      }
    }
  }
}

.mx-datepicker-main {
  &.mx-datepicker-popup {

  }
}
</style>
