import {
    uniArrowLeft,
    uniArrowRight,
    uniBookmark,
    uniBookmarkSolid,
    uniBright,
    uniBrightness,
    uniBrightnessHalf,
    uniBrightnessLow,
    uniBrightnessMinus,
    uniCopyAlt,
    uniEstate,
    uniFavorite,
    uniFavoriteSolid,
    uniFileCopyAlt,
    uniHeart,
    uniInfo,
    uniInfoCircle,
    uniMoon,
    uniMultiply,
    uniQuestion,
    uniRedo,
    uniSun,
    uniToggleOff,
    uniToggleOn,
} from "vue-unicons/dist/icons"

export default [
    uniArrowLeft,
    uniArrowRight,
    uniBookmark,
    uniBookmarkSolid,
    uniBright,
    uniBrightness,
    uniBrightnessHalf,
    uniBrightnessLow,
    uniBrightnessMinus,
    uniCopyAlt,
    uniEstate,
    uniFavorite,
    uniFavoriteSolid,
    uniFileCopyAlt,
    uniHeart,
    uniInfo,
    uniInfoCircle,
    uniMoon,
    uniMultiply,
    uniQuestion,
    uniRedo,
    uniSun,
    uniToggleOff,
    uniToggleOn,
]
