import {LosungByYear} from "@/typings/types";
import IService from "@/services/IService";

export default class CacheService implements IService {

    private localStorage: Storage
    static readonly instance = new CacheService();

    private VERSION_KEY: string = "talosung-data_version"

    constructor() {
        this.localStorage = window.localStorage;
    }

    public init() {
        this.clearLegacyCache()
        this.cacheData(this.VERSION_KEY, this.currentVersion)
    }

    public destroy(): void {
    }

    public getCachedLosungen = async (): Promise<LosungByYear> => {
        return this.getCacheData("FUTURE_LOSUNGEN")
    }

    public clearLegacyCache = () => {
        [
            this.VERSION_KEY,
            `talosung-data_2022`,
            `talosung-data_2023`,
            `talosung-data_2024`,
            `talosung-data_2025`,
        ].forEach(key => {
            this.localStorage.removeItem(key)
        })
        console.debug("[CacheService]", "Cleared cache.")
    }

    get currentVersion(): string {
        return process.env?.VUE_APP_VERSION as string;
    }

    cacheData(cacheKey: string, value: any, merge = false) {
        let _val = value
        if (merge) {
            const cached = this.getCacheData(cacheKey)
            if (cached) {
                _val = {
                    ...cached,
                    ...value,
                }
            }
        }
        this.localStorage.setItem(cacheKey, JSON.stringify(_val))
    }

    getCacheData(cacheKey: string) {
        const storedJson = this.localStorage.getItem(cacheKey);
        if (!storedJson) return null
        return JSON.parse(storedJson)
    }

    removeCacheData(cacheKey: string) {
        this.localStorage.removeItem(cacheKey)
    }
}
