<template>
  <div class="copied-overlay" :data-copied="this.copied">
    <transition name="fade">
      <unicon v-show="this.copied" name="file-copy-alt" class="copy-icon success" width="25"></unicon>
    </transition>
    <transition name="fade">
      <unicon v-show="!this.copied" @click.capture="copyVerses" name="copy-alt" class="copy-icon" width="25"></unicon>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {copyCurrentVerse} from "@/utils/helper";

@Component
export default class CopyIcon extends Vue {

  copied = false;

  copyVerses() {
    copyCurrentVerse(this.$store.getters.versesString)
        .then(() => {
          this.copied = true
          setTimeout(() => {
            this.copied = false
          }, 3000)
        });
  }
}
</script>

<style scoped lang="less">
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

* {
  box-sizing: border-box;
}

.copy-icon {
  cursor: pointer;

  &.success {
    position: absolute;
    left: auto;
    right: auto;
  }
}

.copied-overlay {
  @section-shadow: 0 1rem 3rem var(--shadow-spread-inner);
  @section-shadow-top: 0 -.75rem 3rem var(--shadow-spread-inner);
  background: none !important;

  position: relative;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "Kopiert.";
    display: none;
    position: absolute;
    z-index: 100;
    opacity: 0 !important;
    width: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    transform: scale(0);

    padding: 1rem;
    border-radius: var(--border-radius);

    transition-delay: 300ms;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

    color: var(--success-color) !important;
    background: var(--success-bg-color) !important;
    box-shadow: @section-shadow var(--success-color), @section-shadow-top var(--success-bg-color);
  }

  &[data-copied="true"] {
    &:before {
      opacity: 1 !important;
      transform: scale(1);
    }
  }
}
</style>
