import Vue from "vue"
import Vuex from "vuex"
import CacheService from "@/services/CacheService";
import {Losung} from "@/typings/types";
import {dateToIsoDateString, isoStringToDateString} from "@/utils/helper";
import StorageService from "@/services/StorageService";
import {skipEnabled} from "@/router";
import DayJS from "@/utils/dayJs";
import DayJs from "@/utils/dayJs";
import {ColorMode} from "@/typings/app-vue";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        colorMode: "" as ColorMode,
        storageReady: false,
        selectedDate: dateToIsoDateString(),
        favorites: "[]",
        simplified: false,
        losungDays: {} as any,
    },
    getters: {
        isInit: (state, getters): boolean => !!getters.currentLosung,
        isDarkMode: (state): boolean => state.colorMode === "dark",

        losungForDate: (state) => (date: string) => {
            const losung = state.losungDays[date]
            return losung
        },
        losungForSelectedDate: (state, getters): Losung | undefined => {
            return getters.losungForDate(state.selectedDate);
        },
        currentLosung: (state, getters): Losung | undefined => {
            return getters.losungForDate(state.selectedDate);
        },

        selectedLocaleDate: (state): Date => getDate(state.selectedDate),
        selectedLocaleDateString: (state): string => getDate(state.selectedDate).toLocaleDateString(),

        currentVerseTitle: function (state, getters): string {
            const losung = getters.currentLosung
            return `${losung.book.book_de} ${losung.book.chapter}, ${losung.book.verses}`;
        },
        versesString: (state, getters): string => {
            const losung: Losung = getters.losungForSelectedDate
            const german = losung.text.german.trim()
            // const german2000 = losung.text.german_sch2000.join("\n").trim()
            const hebrew = losung.text.hebrew.join("\n")
            const greek = losung.text.greek?.join("\n")
            return `${losung.book.book_de} ${losung.book.chapter}, ${losung.book.verses}

${german}
--
${hebrew}
${greek ? "--\n" + greek : ""}

https://talosung.de/date/${state.selectedDate}`
        },
    },
    mutations: {
        setColorMode: (state, colorMode: ColorMode) => {
            return state.colorMode = colorMode;
        },
        setStorageReady: (state, ready: boolean) => state.storageReady = ready,
        setSelectedDate: (state, date: string) => state.selectedDate = date,
        setFavorites: (state, favorites: string) => state.favorites = favorites,
        toggleSimplified: (state) => state.simplified = !state.simplified,
        setLosungDays: (state, losungenByDate: object) => state.losungDays = losungenByDate,
    },
    actions: {
        init: async ({commit, dispatch}, {params}) => {
            const date = params.date || isoStringToDateString()
            commit("setSelectedDate", date)

            await StorageService.instance.init();
            const isStorageReady = StorageService.instance.isReady;
            commit("setStorageReady", StorageService.instance.isReady)
            if (!isStorageReady) {
                return
            }

            let losungen: any = await CacheService.instance.getCachedLosungen()
            if (!losungen) {
                losungen = await StorageService.instance.losungService.loadInitial()
            } else if (!losungen[date]) {
                return await dispatch("updateLosungen", {params})
            }
            commit("setLosungDays", losungen)
        },
        updateLosungen: async ({commit, dispatch, state, getters}, {params}) => {
            const losungen = await StorageService.instance.losungService.getSurroundingLosungenFromDate(params?.date)
            commit("setLosungDays", losungen)
        },
        addFavorite: async ({commit}, date: string) => {
            const storage = StorageService.instance
            await storage.addDateAsFavorite(date)
            const faves = await storage.getAllFavorites()
            commit("setFavorites", JSON.stringify(faves))
        },
        removeFavorite: async ({commit}, date: string) => {
            const storage = StorageService.instance
            await storage.removeDateFromFavorites(date)
            const faves = await storage.getAllFavorites()
            commit("setFavorites", JSON.stringify(faves))
        },
        toggleColorMode: ({commit, state}, {colorMode}) => {
            const bodyClasses = document.documentElement;
            const colorModeAttr = "color-mode"
            commit("setColorMode", colorMode)
            bodyClasses.setAttribute(colorModeAttr, colorMode)
        },
    },
    modules: {},
})

const getDate = (dateParam: string): Date => {
    const defaultDateObj = new Date();
    if (!dateParam) return defaultDateObj;

    if (!skipEnabled()) {
        const earliestDate = "2022-01-01";
        const dayjsDate = DayJS(dateParam);

        if (dayjsDate.toDate() > defaultDateObj) return defaultDateObj;
        if (dayjsDate.toDate() <= DayJs(earliestDate).toDate()) return DayJS(earliestDate).toDate();
    }

    return DayJS(dateParam).toDate();
}
