<template>
  <div id="app">
    <transition key="content-tr" name="fade1">
      <logo-loader v-show="isInitial && !$store.getters.isReady && ($route?.path !== '/about')"/>
    </transition>

    <router-view :key="$route.fullPath"/>
    <bottom-nav/>

    <refresh-flag :active="updateExists" @click="refreshPwa"/>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {RouterView} from "vue-router";
import LogoLoader from "@/components/LogoLoader.vue";
import BottomNav from "@/components/BottomNav.vue";
import Header from "@/components/header/Header.vue";
import PwaUpdate from "@/mixin/pwa-update";
import RefreshFlag from "@/components/RefreshFlag.vue";
import VerseHeader from "@/components/header/VerseHeader.vue";
import CacheService from "@/services/CacheService";

@Component({
  components: {
    VerseHeader,
    RefreshFlag,
    Header,
    BottomNav,
    LogoLoader,
    RouterView,
  },
  mixins: [PwaUpdate],
})
export default class App extends Vue {
  private isInitial: boolean = true;

  async beforeCreate() {
    this.themeService.setStore(this.$store)
    this.themeService.init()
  }

  async beforeMount() {
    this.loadData()
  }

  mounted() {
    setTimeout(() => {
      this.isInitial = false
    }, (Math.random() * 1000) + 500)
  }

  beforeDestroy() {
    this.themeService.destroy()
    CacheService.instance.destroy()
  }

  setPageMeta() {
    if (!this.$store.getters.isReady) {
      return
    }
    document.querySelector(`meta[name="description"]`)
        ?.setAttribute("content", `${this.$store.getters.currentVerseTitle} • ${this.$store.getters.selectedLocaleDate.toLocaleDateString("de-DE", {
          year: "numeric", month: "short", day: "numeric",
        })}`)
    const title = `Talosung - ${this.$store.getters.currentVerseTitle}`
    document.title = title
    this.metadataService.setMetadata({
      losung: this.$store.getters.currentLosung,
      url: this.$route.fullPath,
      title: title,
    })
  }

  private async loadData() {
    await this.$store.dispatch("init", {params: this.$route.params})
    this.setPageMeta()
  }

  @Watch("$route.fullPath")
  async onRoutePathChange() {
    await this.loadData()
    console.groupEnd()
  }
}
</script>

<style lang="less">
.view {
  position: relative;
  margin-bottom: 4rem;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  #content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8px;
  }
}
</style>

<style lang="less">
@import '@/style/main.less';

.about-link {
  position: fixed;
  left: .25rem;
  bottom: 1.5rem;
  padding: .75rem;
  margin: .25rem;
  .rounded;

  width: 25px;
  height: 25px;

  opacity: .1;

  @media @desktop {
    left: 1.25rem;
    bottom: 1.5rem;
  }

  @media @tablet {
    left: 1.25rem;
    bottom: 1.5rem;
  }
}
</style>
