<template>
  <div id="logo-loader-container">
    <div class="anim-scale">
      <img src="/favicon.svg" alt="Talosung icon" class="anim-pulse anim-scale">
    </div>

    <footer>
      <router-link :to="$route.path === '/about' ? '/' : '/about'" class="about-link" aria-label="Go to about page">
        <unicon name="info" :width="25"></unicon>
      </router-link>
      <p class="version">
        v.{{ version }}
      </p>
    </footer>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import ContentView from "@/components/views/ContentView.vue";

@Component({
  components: {BaseView: ContentView},
})
export default class AboutView extends Vue {

  get version() {
    return process.env.VUE_APP_VERSION
  }
}
</script>

<style scoped lang="less">
@import '@/style/main.less';


footer {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 1.75rem;

  .version {
    font-style: italic;
    margin-top: 0rem;
    opacity: .4;
    font-size: small;
  }
}

#logo-loader-container {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .centered;

  .anim-scale {
    width: 8rem;
    height: 8rem;
  }

  img, .anim-scale {
    max-width: 7rem !important;
    animation-play-state: running;
  }

  .anim-scale {
    animation: scale-animated-img 2s ease-in-out forwards infinite;
  }

  .anim-pulse {
    animation: turn-animated-img 2s cubic-bezier(.18, 1.45, .23, .96) forwards infinite;
  }
}

@keyframes turn-animated-img {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale-animated-img {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
