import dayjs from "dayjs";

export const dateToIsoDateString = (date: Date | dayjs.Dayjs = new Date()) => {
    return isoStringToDateString(date.toISOString())
}

export const isoStringToDateString = (iso: string = new Date().toISOString()): string => {
    return iso.split("T")[0]
}

export const copyCurrentVerse = (versesString: string) => {
    return navigator.clipboard.writeText(versesString)
}

export const longpressFnc = (timeout: number = 1000) => {
    const PRESS_TIMEOUT = timeout
    return ({
        bind: function (el: Element, {value}: any, vNode: any) {
            if (typeof value !== "function") {
                console.warn(`Expect a function, got ${value}`)
                return
            }

            let pressTimer: NodeJS.Timeout | number | null = -1

            const start = (e: Event & any) => {
                if (e.type === "click" && e.button !== 0) {
                    return;
                }

                if (pressTimer === null) {
                    pressTimer = setTimeout(() => value(e), PRESS_TIMEOUT)
                }
            }

            const cancel = () => {
                    if (pressTimer !== null) {
                        clearTimeout(pressTimer)
                        pressTimer = null
                    }
                }

            ;["mousedown", "touchstart"].forEach(e => el.addEventListener(e, start))
            ;["click", "mouseout", "touchend", "touchcancel"].forEach(e => el.addEventListener(e, cancel))
        },
    });
}

export const HE_VOCALIZATION_RE = /[\u0591-\u05BD\u05BF\u05C1\u05C2\u05C4\u05C5\u05C7]/g;
export const stripPunctuation = (word: string) => {
    // @see: https://en.wikipedia.org/wiki/Unicode_and_HTML_for_the_Hebrew_alphabet
    return word.replace(HE_VOCALIZATION_RE, "")
}

export const hasHighlightedText = () => {
    try {
        const selection = window.getSelection || document.getSelection;
        return selection()?.type === "Range";
    } catch (e) {
    }
};
