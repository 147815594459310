<template>
  <div class="bottom-nav-container bottom-nav-container-verses">
    <nav>
      <icon-button custom="true"
                   padded="true"
                   @click.stop="toggleVocalization">
        <div class="vocalization-letter">
          <span show="true">{{ HEBREW_LETTER_SIMPLE }}</span>
          <span :show="!$store.state.simplified">{{ HEBREW_LETTER_COMPLEX }}</span>
        </div>
      </icon-button>
    </nav>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class BottomNavVerses extends Vue {

  private HEBREW_LETTER_SIMPLE = "ת";
  private HEBREW_LETTER_COMPLEX = "תָּ֕";

  toggleVocalization() {
    this.$store.commit("toggleSimplified")
  }

}
</script>

<style scoped lang="less">
@import '@/style/main.less';

.bottom-nav-container-verses {
  position: absolute;
  width: 100%;
  right: 0;
  height: 100%;
  .bottom-nav-container();

  nav {
    position: absolute;
    background: transparent;
    left: auto;
    right: 0;
    top: -50%;
    margin-right: .5rem;
  }
}

::v-deep .icon {
  padding-left: .5rem !important;
  padding-right: .5rem !important;

  .vocalization-letter {
    .text;
    .centered;
    position: relative;
    font-size: 25px;
    text-align: center;
    padding: 1rem 2rem !important;

    span {
      position: absolute;
      opacity: 0;
      .transition-quicker;
      transition-property: opacity;
      //line-height: 1.5rem;

      &[show="true"] {
        opacity: 1;
      }
    }
  }
}
</style>
