import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import "@/style/variables.less"
import "@/style/main.less"
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd"
import dayjs from "dayjs";
import UniconIcons from "@/assets/icons"
import StorageService from "@/services/StorageService";
import ThemeService from "@/services/ThemeService";
import {longpressFnc} from "@/utils/helper";
import MetadataService from "@/services/MetadataService";

dayjs.locale("de");

Unicon.add(UniconIcons)
Vue.use(Unicon, {
    height: 25,
    width: 25,
})

Vue.directive('longpress', longpressFnc())
Vue.directive('midpress', longpressFnc(750))
Vue.directive('shortpress', longpressFnc(250))

Vue.use({
    install: (Vue1: Vue & any, options) => {
        const themeService = new ThemeService({store})
        const metadataService = new MetadataService({store})
        const storage = StorageService.instance

        Vue1.prototype.storage = Vue1.storage = storage
        Vue1.prototype.themeService = Vue1.themeService = themeService
        Vue1.prototype.metadataService = Vue1.metadataService = metadataService
        Vue1.prototype.isDev = Vue1.isDev = process.env.NODE_ENV !== "production"
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
