<template>
  <div id="content">
    <div class="view">
      <verse-header v-if="!headerless" :title="$route.fullPath"/>
      <slot name="header"></slot>
      <main>
        <slot></slot>
      </main>
      <footer>
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import VerseHeader from "@/components/header/VerseHeader.vue";

@Component({
  components: {VerseHeader}
})
export default class ContentView extends Vue {
  @Prop({ default: false }) headerless!: boolean;
}
</script>

<style lang="less" scoped>
.view {
  max-width: var(--max-width);
  width: 100%;
}

main {
  margin: 1rem 0 2.5rem;
}
</style>
